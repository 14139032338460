import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react'
import MenuModal from '../components/MenuModal/MenuModal'
import ShotModal from '../components/ShotModal/ShotModal'
import EditProfileModal from '../components/EditProfileModal/EditProfileModal'
import { useLocation } from 'react-router-dom'
import Shot from '../components/Shot/Shot'
import { AnimatePresence } from 'framer-motion'
import Case from '../components/Case/Case'
import Waitlist from '../components/Waitlist/Waitlist'

interface valueType {
  modal: 'menu' | 'shot' | 'edit-profile' | 'upload-shot' | 'case' | 'waitlist' | undefined
  setModal: Dispatch<SetStateAction<'menu' | 'shot' | 'edit-profile' | 'upload-shot' | 'case' | 'waitlist' | undefined>>
  editProfileView: 'experience' | 'education' | 'skills' | 'details' | undefined
  setEditProfileView: Dispatch<SetStateAction<'experience' | 'education' | 'skills' | 'details' | undefined>>
  username: string | undefined
  setUsername: Dispatch<SetStateAction<string | undefined>>
  id: number | undefined
  setId: Dispatch<SetStateAction<number | undefined>>
  referral: string | undefined
  setReferral: Dispatch<SetStateAction<string | undefined>>
}

export const ModalContext = createContext<any>(undefined)

export const useModal = () => {
  return useContext(ModalContext)
}

export const ModalContextProvider = ({ children }: { children: React.ReactElement<any | any> }) => {
  const [modal, setModal] = useState<
    'menu' | 'shot' | 'edit-profile' | 'upload-shot' | 'case' | 'waitlist' | undefined
  >()
  const [editProfileView, setEditProfileView] = useState<
    'experience' | 'education' | 'skills' | 'details' | undefined
  >()
  const [username, setUsername] = useState<string | undefined>(undefined)
  const [id, setId] = useState<number | undefined>(undefined)
  const [referral, setReferral] = useState<string | undefined>(undefined)
  const { pathname } = useLocation()

  const value: valueType = {
    modal,
    setModal,
    editProfileView,
    setEditProfileView,
    setUsername,
    setId,
    id,
    username,
    referral,
    setReferral,
  }

  return (
    <ModalContext.Provider value={value}>
      {modal === 'menu' && <MenuModal />}
      {modal === 'upload-shot' && <ShotModal />}
      <AnimatePresence>{modal === 'shot' && <Shot />}</AnimatePresence>
      <AnimatePresence>{modal === 'case' && <Case />}</AnimatePresence>
      {modal === 'edit-profile' && <EditProfileModal />}
      {!modal && pathname.includes('/s/') && <Shot external />}
      <AnimatePresence>{modal === 'waitlist' && <Waitlist />}</AnimatePresence>

      {children}
    </ModalContext.Provider>
  )
}
