import Avatar from '../Avatar/Avatar'
import Icons from '../Icons'
import { useModal } from '../../context/ModalContext'
import './MenuModal.scss'

const MenuModal = () => {
  const { setModal, setEditProfileView } = useModal()

  const editProfileOption = [
    {
      name: 'Experience',
      icon: 'experience',
    },
    {
      name: 'Education',
      icon: 'education',
    },
    {
      name: 'Skills',
      icon: 'skills',
    },
    {
      name: 'General',
      icon: 'details',
    },
  ]
  return (
    <div className="overlay">
      <div className="menu-modal">
        <div className="flex flex-row items-center justify-between">
          <Avatar name={'Abdirahim Arrale'} size={'sm'} />
          <span className="opacity-50 hover:opacity-100 transition cursor-pointer" onClick={() => setModal('')}>
            <Icons name="close" />
          </span>
        </div>

        <button className="btn btn-outline mt-3 w-full btn-icon">
          {/* <Icons name="instagram" /> */}
          Upload
        </button>

        <div>
          <p className="text-sm font-medium opacity-50 mt-3">Settings</p>
          <div className="flex flex-col gap-2 mt-1">
            <div
              className="flex flex-row gap-2 items-center cursor-pointer"
              onClick={() => {
                setModal('edit-profile')
                setEditProfileView('Account')
              }}
            >
              <Icons name="user" />
              <p>Account</p>
            </div>
            <div
              className="flex flex-row gap-2 items-center cursor-pointer"
              onClick={() => {
                setModal('edit-profile')
                setEditProfileView('Preferences')
              }}
            >
              <Icons name="preferences" />
              <p>Preferences</p>
            </div>
          </div>
        </div>

        <div className="divider-x" />

        <div>
          <p className="text-sm font-medium opacity-50 mt-3">Edit profile</p>
          <div className="flex flex-col gap-2 mt-1">
            {editProfileOption.map((option) => (
              <div
                key={option.name}
                className="flex flex-row gap-2 items-center cursor-pointer"
                onClick={() => {
                  setModal('edit-profile')
                  setEditProfileView(option.name)
                }}
              >
                <Icons name={option.icon} />
                <p>{option.name}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="divider-x" />

        <div>
          <p className="text-sm font-medium opacity-50 mt-3">Recently joined</p>
          <div className="flex flex-col gap-3 mt-1">
            <Avatar
              img="https://cdn.dribbble.com/users/108183/avatars/normal/1cdafba45e41c543c19c9f43b86cd2c7.jpg?1501240798"
              profession={'Creative director'}
              name={'Massimo Colonna'}
            />
            <Avatar
              img="https://cdn.dribbble.com/users/2671670/avatars/normal/f441922cc3d7e63637d15a8c415e5cf9.png?1640168146"
              profession={'Designer'}
              name={'Yoan Almeida'}
            />
            <Avatar
              img="https://cdn.dribbble.com/users/536/avatars/normal/cdaf4ac9f736d34fce2209c6c9d543f8.jpeg?1658320745"
              profession={'Art'}
              name={'James Curran'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuModal
