import { Squircle } from '@squircle-js/react'
import { motion } from 'framer-motion'
import './Experience.scss'

const Experience = ({ title, data }: { title: string; data: any }) => {
  const intials = (name: string) =>
    name && name.charAt(0) + (name.split(' ').length > 1 ? name.split(' ')[1].charAt(0) : '')

  return (
    <motion.div
      className="experience h-full"
      initial={{ opacity: 0, transform: 'translateY(-20px)' }}
      animate={{ opacity: 1, transform: 'translateY(0px)' }}
      exit={{ opacity: 0, transform: 'translateY(-20px)' }}
    >
      <div className="experience__bar mb-2 p-5 pb-2">
        <p className="text-sm opacity-50 font-medium">{title}</p>
      </div>

      <div className="flex flex-col gap-8">
        {data.map((company: any) => (
          <div className="flex  md:flex-row gap-2 md:gap-3 items-center px-5" key={company.id}>
            <Squircle cornerRadius={10} cornerSmoothing={0.6} className="experience__logo flex-none">
              {company.logo_src && <img src={require(`../../assets/logo/${company.logo_src}`)} alt="business logo" />}
              {!company.logo_src && <p className="font-medium">{intials(company.company)}</p>}
            </Squircle>

            <div className="max-w-[500px]">
              <p className="text-sm opacity-50">
                {company.from} – {company.to}
              </p>
              <p className="text-base font-medium">
                {company.title} <span className=" opacity-50 font-normal">at</span> {company.company}
              </p>
              <p className="text-sm">{company.desc}</p>
            </div>
          </div>
        ))}
        {data.length === 0 && <p className="text-xs opacity-25 px-5 font-medium">Not listed</p>}
        <div className="fade"></div>
      </div>
    </motion.div>
  )
}

export default Experience
