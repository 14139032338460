import { Squircle } from '@squircle-js/react'
import Icons from '../Icons'
import './Link.scss'
import { Link as RLink } from 'react-router-dom'
const Link = ({ url, size }: { url: string; size?: 'sm' | 'md' | 'lg' | 'full' }) => {
  return (
    <RLink to={url} target="_blank">
      <Squircle cornerRadius={7} cornerSmoothing={0.6} className="link">
        <p className="text-sm">{url.split('.')[1]}</p>
        <Icons name="link" />
      </Squircle>
    </RLink>
  )
}

export default Link
