const EditExperience = () => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row gap-3">
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="title" className="text-sm font-medium opacity-50">
            Title*
          </label>
          <input type="text" className="input input-primary" id="title" placeholder="Senior Designer" />
        </div>

        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="company" className="text-sm font-medium opacity-50">
            Company*
          </label>
          <input type="text" className="input input-primary" id="company" placeholder="Nike" />
        </div>
      </div>

      <div className="flex flex-row gap-3">
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="title" className="text-sm font-medium opacity-50">
            From*
          </label>

          <div className="flex flex-row gap-3">
            <select className="input input-primary">
              <option value="year" selected disabled hidden>
                Year
              </option>
              {Array.from(Array(50).keys()).map((year) => (
                <option value={2022 - year} key={year}>
                  {2022 - year}
                </option>
              ))}
            </select>

            <select className="input input-primary">
              <option value="month" selected disabled hidden>
                Month
              </option>

              {months.map((month) => (
                <option value={month} key={month}>{month}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="to" className="text-sm font-medium opacity-50">
            To*
          </label>
          <div className="flex flex-row gap-3">
            <select className="input input-primary">
              <option value="year" selected disabled hidden>
                Year
              </option>
              <option value="Present">Present</option>
              {Array.from(Array(50).keys()).map((year) => (
                <option value={2022 - year} key={'edu' + year}>{2022 - year}</option>
              ))}
            </select>

            <select className="input input-primary">
              <option value="month" selected disabled hidden>
                Month
              </option>
              {months.map((month) => (
                <option value={month} key={'edu' + month}>{month}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-3">
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="location" className="text-sm font-medium opacity-50">
            Location
          </label>
          <input type="text" className="input input-primary" id="location" placeholder="Tokyo, Japan" />
        </div>

        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="website" className="text-sm font-medium opacity-50">
            Company website
          </label>
          <input type="text" className="input input-primary" id="website" placeholder="https://www.nike.com" />
        </div>
      </div>

      <div>
        <label htmlFor="description" className="text-sm font-medium opacity-50">
          Description
        </label>
        <textarea className="textarea textarea-primary textarea-lg" placeholder="Write here..." />
      </div>

      <div className="flex flex-row gap-3">
        <button className="btn btn-secondary w-full">Discard</button>
        <button className="btn btn-primary w-full disabled">Save</button>
      </div>
    </div>
  )
}

export default EditExperience
