import { useState } from 'react'
import emailIcon from '../../assets/icons/email.svg'
import ShotCard from '../../components/ShotCard/ShotCard'
import discover from '../../data/discover.json'
import { useModal } from '../../context/ModalContext'
import axios from 'axios'
import Icons from '../../components/Icons'

const Home = () => {
  const [email, setEmail] = useState('')
  const [, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { setModal, setReferral } = useModal()

  const handleSubmit = async () => {
    setLoading(true)
    setErrorMessage('')
    const api_url = 'https://api.getwaitlist.com/api/v1/waiter'
    const isRefferd = window.location.search.includes('ref_id')
    const urlParams = new URLSearchParams(window.location.search)
    const ref_id = urlParams.get('ref_id')

    let data: any = {
      email,
      waitlist_id: 8840,
    }

    if (isRefferd) {
      data = {
        ...data,
        referral_link: 'https://www.hanalink.co/?ref_id=' + ref_id,
      }
    }

    axios
      .post(api_url, data)
      .then((res) => {
        setReferral(res.data.referral_link)
        setModal('waitlist')
        setLoading(false)
        setEmail('')
      })
      .catch((err) => {
        setErrorMessage(err.response.data.error_string)
        setLoading(false)
      })
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit()
      e.target.blur()
    }
  }

  const handleEmail = (e: any) => {
    setEmail(e.target.value)
  }

  // hanh sencon lumicon hanaki lance cacao hanami pancham snivy linkdex
  return (
    <div className="flex flex-col gap-7">
      <div className="flex flex-col justify-center text-center">
        <h1 className="text-2xl font-medium">hanalink</h1>
        <p className="mb-3">professional network for creatives</p>
        <div className="relative w-[350px] md:w-[410px] m-auto">
          <input
            type="text"
            style={{
              backgroundImage: `url(${emailIcon})`,
            }}
            className="input  input-icon"
            placeholder="Enter email to join the waitlist"
            value={email}
            onKeyDown={handleKeyPress}
            onChange={handleEmail}
          />
          <span
            className="cursor-pointer hover:opacity-50 transition duration-300"
            style={{ position: 'absolute', right: '15px', top: '10px' }}
            onClick={handleSubmit}
          >
            <Icons name="enter" />
          </span>
        </div>
        {errorMessage && <p className="text-sm text-red-500 mt-2 font-medium">{errorMessage}</p>}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6 fade-up">
        {discover.map((details: any, index) => (
          <ShotCard
            key={'shot-' + index}
            type="lg"
            id={details.shot.id}
            user={details.user}
            postType={details.shot.type}
            desc={details.shot.desc}
            total={details.shot.total}
            title={details.shot.title}
            aspectRatio={details.shot.aspectRatio}
            createdAt={details.shot.createdAt}
            thumbnail={details.shot.thumbnail}
            color={details.shot.color}
          />
        ))}
      </div>
    </div>
  )
}

export default Home
