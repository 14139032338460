import Experience from '../../../components/Experience/Experience'
import Skills from '../../../components/Skills/Skills'

const About = ({ exp, edu, skills }: any) => {
  // const EXP = [
  //   {
  //     id: 1,
  //     logo: 'nike',
  //     date: 'Jan 12 – Present',
  //     title: 'Head of Design',
  //     name: 'Nike',
  //     description:
  //       'I am a designer, developer, and writer. I work as a product designer at Facebook. Previously, I was a design intern at Airbnb. I studied computer science at Stanford.',
  //   },
  //   {
  //     id: 2,
  //     logo: 'github',
  //     date: 'Mar 20 – Jan 23',
  //     title: 'Engineer',
  //     name: 'Nike',
  //     description: 'Working on the design system and design tools team.',
  //   },
  //   {
  //     id: 3,
  //     logo: 'instagram',
  //     date: 'Oct 16 – Mar 20',
  //     title: 'Senior Engineer',
  //     name: 'Instagram',
  //     description: 'This experience helped me grow as a designer and engineer',
  //   },
  //   {
  //     id: 4,
  //     logo: 'twitter',
  //     date: 'Dec 12 – Oct 16',
  //     title: 'Engineer',
  //     name: 'Twitter',
  //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum',
  //   },
  // ]

  // const EDU = [
  //   {
  //     id: 1,
  //     logo: 'experience',
  //     date: 'Jan 23 – Present',
  //     title: 'Design',
  //     name: 'University of California, Berkeley',
  //     description:
  //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum, dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum',
  //   },
  //   {
  //     id: 2,
  //     logo: 'education',
  //     date: 'Mar 20 – Jan 23',
  //     title: 'Head of Design',
  //     name: 'Nike',
  //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum',
  //   },
  // ]

  return (
    <div>
      <div className="flex flex-col gap-5 lg:flex-row">
        <div className="flex flex-col gap-5 w-full">
          <Experience title="Experience" data={exp} />
          <span className="hidden md:block">
            <Skills skills={skills}/>
          </span>
        </div>

        <div className="flex flex-col gap-5 w-full">
          <Experience title="Education" data={edu} />
          <span className="block md:hidden">
            <Skills skills={skills}/>
          </span>
        </div>
      </div>
    </div>
  )
}

export default About
