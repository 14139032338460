import { Link } from 'react-router-dom'
import { useModal } from '../../context/ModalContext'
import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import Avatar from '../Avatar/Avatar'
import Icons from '../Icons'
import { useOnClickOutside } from '../../hooks/onClickOutside'
import users from '../../data/users.json'

const Shot = ({ external = false }: { external?: boolean }) => {
  const { setModal, id, username } = useModal()
  const ref = useRef(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [activeId, setActiveId] = useState(0)

  // const location = useLocation()
  // const queryString = window.location.search
  // const urlParams = new URLSearchParams(queryString)
  // const id = urlParams.get('id')
  // const username = urlParams.get('username')

  const user: any = users.find((user) => user.username === username)

  const shot = user?.shots.find((shot: any) => shot.id === Number(id))

  useOnClickOutside(ref, () => {
    setModal('')
  })

  const handleKeyDown = (e: any) => {
    if (e.key === 'Escape') {
      setModal('')
    }
  }

  useEffect(() => {
    // document.body.style.overflow = 'hidden'
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      // document.body.style.overflow = 'auto'
    }
  })

  console.log(isLoaded)
  return (
    <motion.div
      className="overlay overlay-center p-3"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {shot && (
        <div ref={ref}>
          <div className="flex flex-row justify-between items-center mb-2">
            <Link
              to={`/${username}`}
              onClick={() => {
                setModal('')
                window.scrollTo(0, 0)
              }}
            >
              <Avatar name={user.name} img={user.avatar === 'undefined' ? undefined : user.avatar} size="sm" />
            </Link>
            {!external && (
              <span
                className="opacity-50 hover:opacity-100 transition cursor-pointer float-right"
                onClick={() => {
                  setModal('')
                }}
              >
                <Icons name="close" />
              </span>
            )}
          </div>

          <motion.div
            className="flex flex-col gap-3"
            initial={{ transform: 'translatey(20px)' }}
            animate={{ transform: 'translatey(0)' }}
            exit={{ transform: 'translatey(20px)' }}
          >
            <div>
              <div className="relative">
                {activeId !== 0 && (
                  <div className="absolute top-0 left-0 w-1/2 h-full" onClick={() => setActiveId(activeId - 1)}>
                    <div className="angle">
                      <Icons name="angle-left" />
                    </div>
                  </div>
                )}
                {shot.images.map((img: any, i: number) => (
                  <img
                    src={require(`../../assets/shots/${img.url}`)}
                    style={{
                      display: i === activeId ? 'block' : 'none',
                      maxHeight: '75vh',
                      objectFit: 'cover',
                      backgroundColor: user.shots.color || '#f2f2f2',
                      opacity: isLoaded ? '100%' : '0%',
                      transition: 'opacity 0.5s ease',
                      userSelect: 'none',
                    }}
                    loading="eager"
                    onLoad={() => setIsLoaded(true)}
                    key={'img' + i}
                    alt="thumbnail"
                  />
                ))}

                {activeId !== shot.total - 1 && (
                  <div className="absolute top-0 right-0 w-1/2 h-full" onClick={() => setActiveId(activeId + 1)}>
                    <div className="angle angle-right">
                      <Icons name="angle-right" />
                    </div>
                  </div>
                )}
              </div>

              {shot.total > 1 && (
                <div className="flex flex-row gap-1 justify-center mt-3">
                  {Array.from(Array(shot.total).keys()).map((i: number) => (
                    <div
                      className={`${i === activeId ? 'bg-blue-400' : 'bg-gray-300'} w-[7px] h-[7px] rounded-full`}
                      key={i}
                    />
                  ))}
                </div>
              )}
            </div>
            <div>
              <h2 className="text-lg md:text-xl font-medium">{shot.title}</h2>
              <p className="text-sm md:text-md max-w-[600px]">{shot.desc}</p>
            </div>
          </motion.div>
        </div>
      )}
    </motion.div>
  )
}

export default Shot
