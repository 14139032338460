import { useModal } from '../../context/ModalContext'
import Icons from '../Icons'
import { useRef, useState } from 'react'
import './ShotModal.scss'
import { useOnClickOutside } from '../../hooks/onClickOutside'

type aspectTypes = { name: 'Square' | 'Landscape' | 'Portrait' | 'Fullscreen'; ratio: '1/1' | '16/9' | '4/5' | '4/3' }[]

const ShotModal = () => {
  const ref = useRef(null)
  const { setModal } = useModal()
  const [aspectRatio, setAspectRatio] = useState<'1/1' | '16/9' | '4/5' | '4/3'>('1/1')

  const aspectRatios: aspectTypes = [
    { name: 'Square', ratio: '1/1' },
    { name: 'Landscape', ratio: '16/9' },
    { name: 'Portrait', ratio: '4/5' },
    { name: 'Fullscreen', ratio: '4/3' },
  ]

  useOnClickOutside(ref, setModal(''))

  return (
    <div className="overlay overlay-center flex flex-col gap-1">
      <div ref={ref}>
        <div className="w-[1300px] scale-in">
          <span
            className="opacity-50 hover:opacity-100 transition cursor-pointer float-right"
            onClick={() => setModal('')}
          >
            <Icons name="close" />
          </span>
        </div>
        <div className="shot-modal scale-in">
          <div className="shot-modal__image">
            <img
              src="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/87b289167781301.642eb5514f589.jpg"
              className="rounded-1"
              style={{ aspectRatio, objectFit: 'cover' }}
              alt="thumbnail"
            />

            <div className="flex flex-row gap-1">
              <div className=" bg-blue-600 w-2 h-2 rounded-full opacity-50" />
              <div className=" bg-gray-300 w-2 h-2 rounded-full opacity-50" />
              <div className=" bg-gray-300 w-2 h-2 rounded-full opacity-50" />
            </div>
          </div>

          <div className="shot-modal__details">
            <div className="flex flex-col gap-5">
              <div>
                <p className="text-sm font-medium opacity-50 mb-1">Aspect ratio</p>

                <div className="flex flex-col gap-3">
                  {aspectRatios.map((option) => (
                    <div
                      className={`radio ${aspectRatio === option.ratio ? 'active' : ''}`}
                      onClick={() => setAspectRatio(option.ratio)}
                      key={option.ratio}
                    >
                      <div className="flex flex-row gap-1 items-center">
                        <h3>{option.name}</h3>
                        <p className="text-xs opacity-50">1/1</p>
                      </div>
                      {/* <div className="bg-blue-400 w-4 h-4 rounded-full flex items-center justify-center">
                <div className="bg-white w-2 h-2 rounded-full opacity-75" />
              </div> */}
                      <input
                        type="radio"
                        name="aspect-ratio"
                        checked={aspectRatio === option.ratio}
                        onChange={() => undefined}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <p className="text-sm font-medium opacity-50 mb-1">Shot title</p>
                <input type="text" className="input input-primary" placeholder="Enter a title" />
              </div>

              <div>
                <p className="text-sm font-medium opacity-50 mb-1">Shot description</p>
                <textarea className="textarea textarea-primary" placeholder="Enter a description" />
              </div>

              <div>
                <p className="text-sm font-medium opacity-50 mb-1">Tags</p>

                <div>
                  <input type="text" className="input input-primary mb-1" placeholder="Enter tags" />
                  <p className="text-sm opacity-50">#test</p>
                </div>
              </div>
            </div>
            <button className="btn btn-primary btn-lg">Publish</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShotModal
