import { Route, Routes } from 'react-router-dom'
import { publicRoutes } from './routes/index'
import './App.scss'
import Nav from './components/Nav/Nav'
import { ModalContextProvider } from './context/ModalContext'

function App() {
  return (
    <ModalContextProvider>
      <main className="App">
        <Nav />
        <Routes>
          {publicRoutes.map((route) => (
            <Route path={route.path} element={route.component} key={route.path} />
          ))}
        </Routes>
      </main>
    </ModalContextProvider>
  )
}

export default App
