import { ReactElement } from 'react'
import Profile from '../pages/Profile/Profile'
import Home from '../pages/Home/Home'
// import Shot from '../components/Shot/Shot'

interface routesInterface {
  path: string
  component: ReactElement<any, any>
}
const publicRoutes: routesInterface[] = [
  { path: '/:username', component: <Profile /> },
  { path: '/', component: <Home /> },
  // { path: '/s/:id', component: <Shot />},
]

export { publicRoutes }
