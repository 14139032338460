import { useModal } from '../../context/ModalContext'
import Icons from '../Icons'
import EditExperience from './Options/EditExperience'
import './EditProfileModal.scss'

const EditProfileModal = () => {
  const { setModal, editProfileView, setEditProfileView } = useModal()

  const editProfileOption = [
    {
      name: 'Experience',
      icon: 'experience',
    },
    {
      name: 'Education',
      icon: 'education',
    },
    {
      name: 'Skills',
      icon: 'skills',
    },
    {
      name: 'General',
      icon: 'details',
    },
  ]

  return (
    <div className="overlay overlay-center flex flex-col gap-1">
      <div>
        <div className="w-[900px] scale-in">
          <span
            className="opacity-50 hover:opacity-100 transition cursor-pointer float-right"
            onClick={() => setModal('')}
          >
            <Icons name="close" />
          </span>
        </div>

        <div className="edit-profile-modal scale-in">
          <div className="edit-profile-modal__options">
            <div>
              <p className="text-sm opacity-50 mb-2 ml-4">Account</p>
              <div>
                <button
                  className={`btn w-full btn-icon ${
                    'Account' === editProfileView
                      ? 'btn-outline active'
                      : 'btn-transparent opacity-50 hover:opacity-100'
                  }`}
                  onClick={() => setEditProfileView('Account')}
                >
                  <Icons name="user" />
                  Account
                </button>

                <button
                  className={`btn w-full btn-icon ${
                    'Preferences' === editProfileView
                      ? 'btn-outline active'
                      : 'btn-transparent opacity-50 hover:opacity-100'
                  }`}
                  onClick={() => setEditProfileView('Preferences')}
                >
                  <Icons name="preferences" />
                  Preferences
                </button>
              </div>
            </div>

            <div className="divider divider-x" />

            <div>
              <p className="text-sm opacity-50 mb-2 ml-4">Profile</p>
              <div>
                {editProfileOption.map((option) => (
                  <button
                    className={`btn w-full btn-icon ${
                      option.name === editProfileView
                        ? 'btn-outline active'
                        : 'btn-transparent opacity-50 hover:opacity-100'
                    }`}
                    onClick={() => setEditProfileView(option.name)}
                  >
                    <Icons name={option.icon} />
                    {option.name}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="edit-profile-modal__form">
            <h2 className="text-lg font-medium">{editProfileView}</h2>

            {editProfileView === 'Experience' && <EditExperience />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditProfileModal
