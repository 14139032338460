import { motion } from 'framer-motion'
import Icons from '../Icons'
import { useModal } from '../../context/ModalContext'
import { useOnClickOutside } from '../../hooks/onClickOutside'
import { useEffect, useRef } from 'react'

const Waitlist = () => {
  const { setModal, referral } = useModal()
  const ref = useRef(null)

  useOnClickOutside(ref, () => {
    setModal('')
  })

  const handleKeyDown = (e: any) => {
    if (e.key === 'Escape') {
      setModal('')
    }
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      document.body.style.overflow = 'auto'
    }
  })

  return (
    <motion.div
      className="overlay overlay-center p-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="flex flex-col gap-5 container p-5"
        style={{
          backgroundColor: 'var(--color-light)',
          borderRadius: '15px',
          maxWidth: '500px',
          boxShadow: 'var(--shadow-md)',
        }}
        initial={{ transform: 'translatey(20px)' }}
        animate={{ transform: 'translatey(0)' }}
        exit={{ transform: 'translatey(20px)' }}
        ref={ref}
      >
        <div>
          <div className="flex flex-row items-center justify-between">
            <h1 className="text-lg md:text-xl font-medium">You're on the waitlist 🎉</h1>
            <span
              className="opacity-50 hover:opacity-100 transition cursor-pointer float-right"
              onClick={() => {
                setModal('')
              }}
            >
              <Icons name="close" />
            </span>
          </div>

          <p className="text-sm md:text-base mt-2 max-w-[400px]">
            <span className="opacity-50">Want to cut the line and</span> get early access? Share your referral code with
            friends{' '}
          </p>
        </div>

        <div
          className="flex flex-row gap-3 items-center justify-between container rounded-md p-2 px-3"
          style={{ backgroundColor: 'var(--color-gray-100)', border: 'solid 1px var(--color-gray-300)' }}
        >
          <p className="text-sm opacity-50">{referral}</p>
          <span
            className="hover:opacity-50 transition cursor-pointer"
            onClick={() => navigator.clipboard.writeText(referral)}
          >
            <Icons name="copy" />
          </span>
        </div>

        <div>
          <p className="text-sm my-3">Follow us on our socials ❤️</p>
          <div className="flex flex-row gap-3">
            <a href="https://twitter.com/hanalinkapp" target="_blank" rel="noreferrer">
              <div
                className="flex flex-row gap-1  md:gap-2 items-center container rounded-md p-2 px-3 hover:opacity-50 transition duration-300 cursor-pointer"
                style={{ border: 'solid 1px var(--color-gray-300)' }}
              >
                <Icons name="twitter" />
                <p className="text-sm">Twitter</p>
              </div>
            </a>

            <a href="https://www.instagram.com/hanalink.co/" target="_blank" rel="noreferrer">
              <div
                className="flex flex-row gap-1  md:gap-2 items-center container rounded-md p-2 px-3 hover:opacity-50 transition duration-300 cursor-pointer"
                style={{ border: 'solid 1px var(--color-gray-300)' }}
              >
                <Icons name="instagram" />
                <p className="text-sm">Instagram</p>
              </div>
            </a>
            
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default Waitlist
