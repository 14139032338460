interface Props {
  img?: string | null
  name?: string
  profession?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  details?: boolean
  cstyle?: string
}
const Avatar = ({ img, name, profession, size = 'md', details = true, cstyle }: Props) => {
  const sizes = {
    xs: { width: 16, height: 16 },
    sm: { width: 24, height: 24 },
    md: { width: 32, height: 32 },
    lg: { width: 64, height: 64 },
    xl: { width: 85, height: 85 },
  }

  const { width, height } = sizes[size]

  const intials = name && name.charAt(0) + (name.split(' ').length > 1 ? name.split(' ')[1].charAt(0) : '')

  if (!details) {
    return (
      <>
        {img ? (
          <img src={require(`../../assets/avatars/${img}`)} alt="avatar" className="rounded-full object-cover" style={{ height, width }} />
        ) : (
          <div className="bg-gray-200 rounded-full flex items-center justify-center" style={{ height, width }}>
            {' '}
            <p className="text-sm">{intials}</p>
          </div>
        )}
      </>
    )
  }

  return (
    <div
      className={`flex flex-row gap-2 items-center hover:opacity-50 transition-opacity duration-300 ${
        cstyle ? cstyle : ''
      }`}
    >
      {img ? (
        <img src={require(`../../assets/avatars/${img}`)} alt="avatar" className="rounded-full object-cover" style={{ height, width }} />
      ) : (
        <div className="border border-gray-200 rounded-full flex items-center justify-center" style={{ height, width }}>
          {' '}
          <p className="text-[10px] font-medium">{intials}</p>
        </div>
      )}
      <div>
        {profession && <p className="text-sm opacity-50">{profession}</p>}
        <p className="font-medium text-sm">{name}</p>
      </div>
    </div>
  )
}

export default Avatar
