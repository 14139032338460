import { useState } from 'react'
import Avatar from '../../components/Avatar/Avatar'
import { Squircle } from '@squircle-js/react'
import Link from '../../components/Link/Link'
import ShotCard from '../../components/ShotCard/ShotCard'
import Icons from '../../components/Icons'
import users from '../../data/users.json'
import About from './About/About'
import { AnimatePresence, motion } from 'framer-motion'
import { useLocation, Link as RLink } from 'react-router-dom'
import './Profile.scss'

const Profile = () => {
  const [view, setView] = useState<'work' | 'about'>('work')

  // const [user, setUser] = useState(users[1])

  const { pathname } = useLocation()
  const user = users.find((user) => pathname.includes(user.username))

  if (!user) return null

  const intials = (name: string) =>
    name && name.charAt(0) + (name.split(' ').length > 1 ? name.split(' ')[1].charAt(0) : '')
  return (
    <div className="profile">
      <Squircle cornerRadius={20} cornerSmoothing={0.6} className="profile__header fade-up">
        <div className="flex flex-col sm:flex-row gap-4 sm:items-center">
          <div className="profile__header__avatar md:px-5">
            <Avatar img={user.avatar} size="xl" details={false} />
          </div>
          <div className="profile__header__info">
            <p className="text-sm opacity-50">{user.profession}</p>
            <h3 className="text-lg font-medium">{user.name}</h3>
            <p className="text-sm md:text-md mb-3">{user.bio}</p>

            <Link url={user.website} />

            {/* <button className="btn btn-secondary mt-6 hidden md:block">Follow</button> */}
          </div>
        </div>

        <div className="hidden md:flex flex-row gap-3 h-full">
          <Squircle cornerRadius={15} cornerSmoothing={0.6} className="profile__header__basic">
            <div className="flex flex-col gap-5">
              <div>
                <p className="text-xs font-medium opacity-50 mb-1">Based in</p>
                <div className="flex flex-row items-center">
                  <span className="opacity-50">
                    <Icons name="location" />
                  </span>
                  <p className="text-sm">
                    {user.location.split(',')[0]}
                    <span className="opacity-50">,{user.location.split(',')[1]}</span>
                  </p>
                </div>
              </div>
              <div>
                <p className=" text-xs font-medium opacity-50 mb-1">Skills</p>
                <div className="skill__container flex flex-row relative">
                  <div className=" skill__items flex flex-row gap-2 overflow-auto w-[330px] pr-7">
                    {user.skills.map((skill, index) => (
                      <Squircle cornerRadius={7} cornerSmoothing={0.6} key={'skill-' + index} className="skill">
                        {skill}
                      </Squircle>
                    ))}
                  </div>
                  <div className="fade-left"></div>
                  <div className="fade-right"></div>
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row gap-2">
                {user.socials.map((social, index) => (
                  <RLink
                    to={social.url}
                    target="_blank"
                    key={'socials-' + index}
                    className="social opacity-50 hover:opacity-100 transition cursor-pointer"
                  >
                    <Icons name={social.name.toLowerCase()} />
                  </RLink>
                ))}
              </div>

              <div className="flex flex-row gap-1 items-center">
                <div className="dot w-[14px] h-[14px] rounded-full flex items-center justify-center">
                  <div className="dot__inner w-[6px] h-[6px] rounded-full opacity-50" />
                </div>
                <p className="text-xs">Available for work</p>
              </div>
            </div>
          </Squircle>

          <Squircle
            cornerRadius={15}
            cornerSmoothing={0.6}
            className="relative profile__header__exp overflow-y-auto hidden lg:block"
          >
            <p className="text-xs font-medium opacity-50 mb-1">Experience</p>

            <div className="flex flex-col gap-2">
              {user.experience.map((exp) => (
                <div
                  className="flex flex-row items-center gap-2 cursor-pointer"
                  key={exp.id}
                  onClick={() => setView('about')}
                >
                  <Squircle cornerRadius={7} cornerSmoothing={0.6} className="flex-none exp">
                    {exp.logo_src && <img src={require(`../../assets/logo/${exp.logo_src}`)} alt="business logo" />}
                    {!exp.logo_src && <p className="font-medium">{intials(exp.company)}</p>}
                  </Squircle>
                  <div className="overflow-hidden hover:opacity-50 transition duration-300">
                    <p className="text-xs opacity-50">{`${exp.from} – ${exp.to}`}</p>
                    <h3 className="font-medium text-sm whitespace-nowrap overflow-hidden text-ellipsis">
                      {exp.title} <span className="font-normal opacity-50">at</span> {exp.company}
                    </h3>
                  </div>
                </div>
              ))}
              
               {user.education.map((exp) => (
                <div
                  className="flex flex-row items-center gap-2 cursor-pointer"
                  key={exp.id}
                  onClick={() => setView('about')}
                >
                  <Squircle cornerRadius={7} cornerSmoothing={0.6} className="flex-none exp">
                    {exp.logo_src && <img src={require(`../../assets/logo/${exp.logo_src}`)} alt="business logo" />}
                    {!exp.logo_src && <p className="font-medium">{intials(exp.company)}</p>}
                  </Squircle>
                  <div className="overflow-hidden hover:opacity-50 transition duration-300">
                    <p className="text-xs opacity-50">{`${exp.from} – ${exp.to}`}</p>
                    <h3 className="font-medium text-sm whitespace-nowrap overflow-hidden text-ellipsis">
                      {exp.title} <span className="font-normal opacity-50">at</span> {exp.company}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
            <div className="fade" style={{ bottom: '-20px', height: '50px' }}></div>
          </Squircle>
        </div>
      </Squircle>

      <div>
        <div className="flex flex-row gap-3 fade-up mb-3">
          <p
            className={`font-medium cursor-pointer transition ${view === 'work' ? '' : 'opacity-50 hover:opacity-100'}`}
            onClick={() => setView('work')}
          >
            Work
          </p>
          <p
            className={`font-medium cursor-pointer transition ${
              view === 'about' ? '' : 'opacity-50 hover:opacity-100'
            }`}
            onClick={() => setView('about')}
          >
            About
          </p>
        </div>

        <AnimatePresence>
          {view === 'work' && (
            <motion.div
              className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6"
              initial={{ opacity: 0, transform: 'translateY(20px)' }}
              animate={{ opacity: 1, transform: 'translateY(0px)' }}
              exit={{ opacity: 0, transform: 'translateY(20px)' }}
            >
              {user.shots.map((shot, index) => (
                <ShotCard
                  key={'shot-' + index}
                  user={user}
                  id={shot.id}
                  postType={shot.type}
                  total={shot.total}
                  title={shot.title}
                  color={shot.color}
                  aspectRatio={shot.aspectRatio}
                  desc={shot.desc}
                  createdAt={shot.createdAt}
                  thumbnail={shot.thumbnail}
                />
              ))}
            </motion.div>
          )}

          {view === 'about' && <About edu={user.education} exp={user.experience} skills={user.skills}/>}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default Profile
