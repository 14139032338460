import { Link, useLocation } from 'react-router-dom'
import { useModal } from '../../context/ModalContext'
import Avatar from '../Avatar/Avatar'
// import Icons from '../Icons'
import searchIcon from './searchIcon.svg'
import './Nav.scss'

const Nav = () => {
  const { setModal } = useModal()
  const location = useLocation()

  const currentUser = undefined

  return (
    <div className="nav">
      <div className="flex flex-row gap-1 items-center">
        <Link to={'/'}>
          {/* <Icons name="logo" /> */}
          {/* <div className="w-[12px] h-[12px] rounded-full" style={{ backgroundColor: 'var(--color-blue-600)' }} /> */}
          <p className="font-mono text-sm opacity-50 hover:opacity-100 transition duration-300 cursor-pointer">/–/</p>
        </Link>
        {location.pathname !== '/' && (
          <div className="fade-in">
            <p className="text-xs font-medium opacity-50">{location.pathname.slice(1)}</p>
          </div>
        )}
      </div>

      {currentUser && (
        <input
          type="text"
          style={{
            backgroundImage: `url(${searchIcon})`,
          }}
          className="input input-text"
          placeholder="Search or ⌘K"
        />
      )}

      <div className="flex flex-row gap-2 items-center">
        {currentUser && location.pathname !== '/' && (
          <p
            className="text-sm font-medium opacity-50 hover:opacity-100 cursor-pointer transition"
            onClick={() => setModal('upload-shot')}
          >
            Upload
          </p>
        )}

        {currentUser && location.pathname !== '/' && (
          <div className="cursor-pointer" onClick={() => setModal('menu')}>
            <Avatar profession="Software Engineer" name="Abdi Arrale" details={false} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Nav
