import { Link } from 'react-router-dom'
import { useModal } from '../../context/ModalContext'
import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../../hooks/onClickOutside'
import Avatar from '../Avatar/Avatar'
import Icons from '../Icons'
import data from '../../data/mynor.json'
import './Case.scss'

const Case = () => {
  const { setModal } = useModal()
  const ref = useRef(null)
  const [activeId, setActiveId] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)

  useOnClickOutside(ref, () => {
    setModal('')
  })

  const handleKeyDown = (e: any) => {
    if (e.key === 'Escape') {
      setModal('')
    }
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      document.body.style.overflow = 'auto'
    }
  })

  const ratio: any = {
    portrait: '4 / 5',
    landscape: '16 / 9',
    square: '1 / 1',
    fullscreen: '4 / 3',
  }

  return (
    <motion.div
      className="overlay overlay-center md:p-7"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div ref={ref}>
        <div className="flex flex-row justify-between items-center mt-4 md:mt-0 md:mb-2 p-3 md:p-0">
          <div>
            <Link
              to={`/${data.creators[0].username}`}
              className="w-full"
              onClick={() => {
                setModal('')
                window.scrollTo(0, 0)
              }}
            >
              <Avatar
                name={data.creators[0].name!}
                img={data.creators[0].avatar === 'undefined' ? undefined : data.creators[0].avatar}
                size="sm"
              />
            </Link>
          </div>
          <span
            className="opacity-50 hover:opacity-100 transition cursor-pointer float-right"
            onClick={() => {
              setModal('')
            }}
          >
            <Icons name="close" />
          </span>
        </div>

        <motion.div
          className="case"
          initial={{ transform: 'translatey(20px)' }}
          animate={{ transform: 'translatey(0px)' }}
          exit={{ transform: 'translatey(20px)' }}
        >
          <h1 className="text-2xl mb-1 font-medium">{data.title}</h1>
          <div className="flex flex-col md:flex-row justify-between mb-3">
            <p className="text-sm opacity-50">{data.desc}</p>
            <p className="text-sm opacity-50">
              {new Date(data.createdAt).toLocaleString('en', { month: 'short', year: 'numeric' })}
            </p>
          </div>
          <div className="flex flex-col gap-4 justify-center">
            {data.blocks.map((block: any, i: number) => (
              <>
                {block.type === 'p' && <p key={`${block.id}-${i}`}>{block.content}</p>}
                {block.type === 'image' && (
                  <div key={`${block.id}-${i}`}>
                    <div className="flex flex-row gap-1 items-center opacity-50 my-3">
                      <span>
                        <Icons name="picture" />
                      </span>
                      <p className="text-sm">Some stills</p>
                    </div>
                    <div className="relative">
                      {activeId !== 0 && (
                        <div className="absolute top-0 left-0 w-1/2 h-full" onClick={() => setActiveId(activeId - 1)}>
                          <div className="angle" onClick={() => setActiveId(activeId - 1)}>
                            <Icons name="angle-left" />
                          </div>
                        </div>
                      )}

                      {block.content.map((img: any, i: number) => (
                        <img
                          src={require(`../../assets/shots/${img.url}`)}
                          style={{
                            display: i === activeId ? 'block' : 'none',
                            aspectRatio: ratio[block.aspectRatio],
                            maxHeight: '75vh',
                            borderRadius: '5px',
                            objectFit: 'cover',
                            backgroundColor: block.color || '#f2f2f2',
                            opacity: isLoaded ? '100%' : '0%',
                            transition: 'opacity 0.5s ease',
                            userSelect: 'none',
                          }}
                          loading="eager"
                          onLoad={() => setIsLoaded(true)}
                          alt="thumbnail"
                        />
                      ))}

                      {activeId !== block.total - 1 && (
                        <div className="absolute top-0 right-0 w-1/2 h-full" onClick={() => setActiveId(activeId + 1)}>
                          <div className="angle angle-right" onClick={() => setActiveId(activeId + 1)}>
                            <Icons name="angle-right" />
                          </div>
                        </div>
                      )}

                      {block.total > 1 && (
                        <div className="flex flex-row gap-1 align-center justify-center mt-3 ">
                          {Array.from(Array(block.total).keys()).map((i: number) => (
                            <div
                              className={`${
                                i === activeId ? 'bg-blue-400' : 'bg-gray-300'
                              } w-[7px] h-[7px] rounded-full`}
                              key={i}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {block.type === 'video' && (
                  <div className="block__video" key={`${block.id}-${i}`}>
                    <iframe
                      title={block.content}
                      src={block.content}
                      className="rounded-lg h-52 md:h-80 w-full"
                    ></iframe>{' '}
                  </div>
                )}
              </>
            ))}
          </div>

          <div className="my-7 h-1" />

          <div className="mt-5 w-full">
            <div className="flex flex-row gap-1 items-center opacity-50 my-3">
              <span>
                <Icons name="users" />
              </span>
              <p className="text-sm">Team</p>
            </div>
            <div className="flex flex-col gap-3">
              {data.team.map((team: any, i: number) => (
                <div className="flex gap-3 items-center" key={team.name}>
                  {team?.username && (
                    <Link
                      to={`/${team.username}`}
                      className="flex-none"
                      onClick={() => {
                        setModal('')
                        window.scrollTo(0, 0)
                      }}
                    >
                      <Avatar name={team.name} img={team.avatar} size="sm" />
                    </Link>
                  )}
                  {!team?.username && <Avatar name={team.name} img={team?.avatar} size="sm" cstyle={'flex-none'} />}
                  <div className="grow divider divider-x" />

                  <p className="flex-none text-xs opacity-50">{team.role}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-5">
            <div className="flex flex-row gap-1 items-center opacity-50 my-3">
              <span>
                <Icons name="user" />
              </span>
              <p className="text-sm">Cast</p>
            </div>
            <div className="flex flex-col gap-3">
              {data.cast.map((cast: any, i: number) => (
                <div className="flex gap-3 items-center" key={cast.name}>
                  {cast?.username && (
                    <Link to={`/${cast.username}`} className="flex-none" onClick={() => window.scrollTo(0, 0)}>
                      <Avatar name={cast.name} img={cast?.avatar} size="sm" />
                    </Link>
                  )}
                  {!cast?.username && <Avatar name={cast.name} img={cast.avatar} size="sm" cstyle={'flex-none'} />}

                  <div className="grow divider divider-x" />

                  <p className="flex-none text-xs opacity-50">{cast.role}</p>
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default Case
