import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import Avatar from '../Avatar/Avatar'
import { useModal } from '../../context/ModalContext'
import './ShotCard.scss'
import Icons from '../Icons'
const tinycolor = require('tinycolor2')

interface Props {
  user: { username: string; avatar: string; name: string } //fix this
  title: string
  createdAt: string
  thumbnail: string
  desc?: string
  type?: 'md' | 'lg'
  color?: string
  id: string | number
  postType?: string
  aspectRatio?: string
  total?: number
}

const ShotCard: FC<Props> = ({
  user,
  id,
  title,
  desc,
  createdAt,
  thumbnail,
  postType = 'shot',
  type = 'md',
  aspectRatio,
  total = 1,
  color,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { setModal, setUsername, setId } = useModal()

  // const [isHovered, setIsHovered] = useState(false)
  // style={{ backgroundColor: isHovered ? color + '0D' : 'var(--color-gray-100)', borderColor: isHovered ? color + '1A' : 'var(--color-gray-200)' }}
  // onMouseOver={() => setIsHovered(true)}
  // onMouseLeave={() => setIsHovered(false)}
  // &username=${user.username}&avatar=${user.avatar}&ratio=${aspectRatio}&name=${user.name}&src=${thumbnail}&title=${title}&desc=${desc}&color=${color}
  // window.history.pushState(undefined, 'title', `/s/?id=${id}&username=${user.username}`)

  const borderColor = tinycolor(color).getBrightness() > 230 ? 'solid 1px var(--color-gray-300)' : ''

  const isLight = tinycolor(color).getBrightness() > 200

  switch (type) {
    case 'lg':
      return (
            
            <div className={`shot-card p-2 mb-2 rounded-[15px] transition delay-300`}>
          <div
            onClick={() => {
              setModal(postType)
              setUsername(user.username)
              setId(id)
            }}
            className="relative"
          >
            <img
              src={require(`../../assets/shots/${thumbnail}`)}
              alt={title}
              loading="lazy"
              style={{
                borderRadius: '12px',
                backgroundColor: color || '#f2f2f2',
                objectFit: 'cover',
                opacity: isLoaded ? '1' : '0',
                transition: 'opacity 0.5s ease',
                userSelect: 'none',
              }}
              onLoad={() => setIsLoaded(true)}
              className="shot-card__img cursor-pointer"
            />
            {total > 1 && postType === 'shot' && (
              <span className="absolute top-[15px] right-[15px]">
                <div className="opacity-50">
                  <Icons name="motion" fill={isLight ? '#000' : '#fff'} />{' '}
                </div>
              </span>
            )}

            {postType === 'case' && (
              <span className="absolute top-[15px] right-[15px]">
                <div className="opacity-50">
                  <Icons name="case" fill={isLight ? '#000' : '#fff'} />{' '}
                </div>
              </span>
            )}
            {/* {total! > 1 && (
              <div className="flex flex-row gap-1 m-auto mt-3">
                {Array.from(Array(total).keys()).map((i: number) => (
                  <div className={`${i === 0 ? 'bg-blue-400' : 'bg-gray-300'} w-2 h-2 rounded-full`} />
                ))}
              </div>
            )} */}
          </div>
          {/* <div className='flex flex-row gap-1 mt-2'>

              <div className='border rounded-full px-2'><p className='text-xs opacity-50'>front-end</p></div>
              <div className='border rounded-full px-2'><p className='text-xs opacity-50'>back-end</p></div>
              </div> */}
          <div className="flex grow flex-col gap-2 p-2 justify-between mt-2">
            <div
              className="shot-card__info"
              onClick={() => {
                window.history.pushState(undefined, 'title', `/s/?id=${id}&username=${user.username}`)
                setModal(postType)
              }}
            >
              <h3 className="font-medium">{title}</h3>
              <p className="text-sm limiter">{desc}</p>
            </div>

            <div className="flex gap-3 items-center mt-3">
              <Link to={`/${user.username}`} className="flex-none" onClick={() => window.scrollTo(0, 0)}>
                <Avatar name={user.name} img={user.avatar} size="sm" />
              </Link>

              <div className="grow divider divider-x" />

              <p className="flex-none text-xs opacity-50 whitespace-nowrap m-auto">
                {new Date(createdAt).toLocaleDateString('en', { day: '2-digit', month: 'short', year: 'numeric' })}
              </p>
            </div>
          </div>
        </div>
      )
    case 'md':
      return (
        <div
          onClick={() => {
            setModal(postType)
            setUsername(user.username)
            setId(id)
          }}
        >
          <div className="relative">
            <img
              src={require(`../../assets/shots/${thumbnail}`)}
              alt={title}
              style={{
                objectFit: 'cover',
                borderRadius: '7px',
                backgroundColor: color || '#f2f2f2',
                opacity: isLoaded ? '100%' : '0%',
                transition: 'opacity 0.5s ease',
                border: borderColor,
                userSelect: 'none',
              }}
              loading="lazy"
              onLoad={() => setIsLoaded(true)}
              className="shot-card__img cursor-pointer"
            />
            {total > 1 && postType === 'shot' && (
              <span className="absolute top-[15px] right-[15px]">
                <div className="opacity-50">
                  <Icons name="motion" fill={isLight ? '#000' : '#fff'} />{' '}
                </div>
              </span>
            )}

            {postType === 'case' && (
              <span className="absolute top-[15px] right-[15px]">
                <span className="opacity-50">
                  <Icons name="case" fill={isLight ? '#000' : '#fff'} />{' '}
                </span>
              </span>
            )}
          </div>
          <div className="flex flex-row justify-between mt-2">
            <h3 className="text-sm font-medium">{title}</h3>
            <p className="text-sm opacity-50">
              {new Date(createdAt).toLocaleDateString('en', { day: '2-digit', month: 'short', year: 'numeric' })}
            </p>
          </div>
        </div>
      )
    default:
      return null
  }
}

export default ShotCard
