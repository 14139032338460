const Skills = ({ skills }: { skills: string[] }) => {
  return (
    <div className="container p-5 rounded-[15px] fade-up fade-up--5">
      <p className="text-sm opacity-50 font-medium mb-2">Skills</p>
      <div className="flex flex-col sm:flex-row gap-2">
        {skills.map((skill, index) => (
          <div key={'skill-' + index} className="skill rounded-[8px]" style={{width: 'fit-content'}}>
            {skill}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Skills
